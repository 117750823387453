<template>
  <div class="push-message-manage">
    <a-form :layout="formLayout">
<!--  推送标题    -->
      <a-form-item label="推送标题" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
        <a-input placeholder="请输入推送标题" v-model="pushParams.title" />
      </a-form-item>
<!--   推送内容描述   -->
      <a-form-item label="推送内容描述" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
        <a-input placeholder="请输入描述" v-model="pushParams.content" />
      </a-form-item>

<!--  推送打开数据    -->
      <a-form-item label="打开路径" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
        <a-select
            v-model="openUrlType"
            :filter-option="untils.filterOption"
            placeholder="请选择打开路径"
            allowClear
            style="margin-right: 8px"
            @change="handleChangeSelectedOpenUrlType"
        >
          <a-select-option
              v-for="item of openUrlList"
              :key="item.id"
              :value="item.id"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
<!--  推送地址    -->
      <a-form-item v-if="['testH5', 'proH5'].includes(openUrlType)" label="推送打开地址" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
        <a-input placeholder="请输入推送打开地址" v-model="pushParams.linkUrl" />
      </a-form-item>
<!--  推送测试类型    -->
      <a-form-item label="推送类型"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
      >
        <a-radio-group :default-value="pushParams.testType" @change="handlePushChangeType">
          <a-radio-button :value="0">正式推送</a-radio-button>
          <a-radio-button :value="1">测试环境测试推送</a-radio-button>
          <a-radio-button :value="2">正式环境测试推送</a-radio-button>
        </a-radio-group>
      </a-form-item>
<!--  推送用户    -->
      <a-form-item v-if="[0].includes(pushParams.testType)" label="上传推送用户文件" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
        <a-upload
            :file-list="fileList"
            :remove="handleRemove"
            :before-upload="beforeUpload"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        >
          <a-button v-if="fileList.length < 1"> <a-icon type="upload" /> 选择用户模版文件 </a-button>
          <a-badge color="red" text="请选择.xls文件" style="margin-left: 10px" />
        </a-upload>
      </a-form-item>
<!--  按钮    -->
      <a-form-item :wrapper-col="buttonLayout.wrapperCol">
        <a-popconfirm
            v-if="pushParams.testType === 0"
            title="是否确认群发推送?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleConfirmPushImMessage()"
        >
          <a-button type="primary">群发推送</a-button>
        </a-popconfirm>
        <a-button type="defalut" v-else @click="handleConfirmPushImMessage">测试一下</a-button>
        <a-button
            v-if="pushOverResultFile"
            class="ml-20"
            type="danger"
            icon="download"
            @click="handleDownloadPushOverResult"
        >下载推送结果</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import {pushAppIMMessageApi} from "@/views/cmsPage/dqSys/pushMessageManage/_apis"
import untils from "@/untils"
import {push_message_open_type_list} from "@/views/cmsPage/dqSys/pushMessageManage/_data"
import {listDownloadExcel} from "@/untils/listDownloadExcel"

export default {
  data() {
    return {
      untils,
      fileList: [],
      openUrlList: push_message_open_type_list,
      formLayout: 'horizontal',
      formItemLayout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
      },
      buttonLayout: {
        wrapperCol: { span: 18, offset: 5 },
      },
      pushParams: {
        title: undefined,
        content: undefined,
        linkUrl: undefined,
        testType: 0,
        files: undefined
      },
      pushOverResultFile: undefined,
      openUrlType: undefined,
    }
  },
  async created() {

  },
  methods: {

    async handleConfirmPushImMessage() {
      if (!this.pushParams.title) return this.$message.info('请填写推送标题')
      if (!this.pushParams.content) return this.$message.info('请填写推送描述内容')
      if (!this.pushParams.linkUrl) return this.$message.info('请输入需要打开的链接')
      if (!this.fileList.length && this.pushParams.testType === 0) return this.$message.info('请上传用户模版文件')
      const formData = new FormData();
      this.fileList.forEach((file) => {
        formData.append('files', file);
      });
      formData.append('title', this.pushParams.title);
      formData.append('content', this.pushParams.content);
      formData.append('linkUrl', this.pushParams.linkUrl);
      formData.append('testType', this.pushParams.testType);
      this.pushParams.files = formData;
      const res = await pushAppIMMessageApi(formData)
      console.log(res, '路径')
      if (res.status !== '200') return
      this.pushOverResultFile = res.data
    },
    /** 改变测试推送类型 */
    handlePushChangeType(e) {
      this.pushParams.testType = e.target.value;
    },
    /** 下载推送结果 */
    handleDownloadPushOverResult() {
      // 列标题
      const jsonData = this.pushOverResultFile
      listDownloadExcel(jsonData, '推送结果', ['cellStyleMap'], '')
    },
    /** 切换打开页面 */
    handleChangeSelectedOpenUrlType(e) {
      if (e) {
        this.pushParams.linkUrl = this.openUrlList.find(el => el.id === e).openUrl
      } else {
        this.pushParams.linkUrl = undefined
      }
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
  }
}
</script>
<style scoped lang="scss">
.push-message-manage {
  padding-top: 50px;
  width: 100%;
}
</style>